import React, { useState, useEffect, useMemo } from "react";
import { useSubheader } from "../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { baseURL, filePathURL, webURL, baseURLV1 } from "../service/API.js";
import Swal from "sweetalert2";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Modal, Button, Table, Tooltip, OverlayTrigger, Tab, Tabs } from "react-bootstrap"
import { useIntl } from "react-intl";
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment'
import NumberFormat from 'react-number-format';
import { useLocation } from "react-router-dom";
import { status } from '../service/status.js'
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: theme.spacing.unit / 4,
    },
    noLabel: {
        marginTop: theme.spacing.unit * 3,
    },
    inputColor: {
        backgroundColor: "#F4F6F9",
        borderRadius: "5px",
        borderWidth: "0",
    },
    button: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        margin: theme.spacing(1),
        backgroundColor: "#73b1ef"
    },
}));

export const ClaimTransaction = (props) => {
    const classes = useStyles();
    const [fileUploadDocSign, setFileUploadDocSign] = useState("")
    const [fileUploadDocReject, setFileUploadDocReject] = useState("")
    const [requireCopyCard, setRequireCopyCard] = useState(false)
    const [requireCertificate, setRequireCertificate] = useState(false)
    const [requireBookBank, setRequireBookBank] = useState(false)
    const [requireCopy20, setRequireCopy20] = useState(false)
    const [requireRemark, setRequireRemark] = useState(false)
    const [requireRemark2, setRequireRemark2] = useState(false)

    const [requireRemarkTxt2, setRequireRemarkTxt2] = useState("")

    const [showRejectPopup, setShowRejectPopup] = useState(false)
    const [selectedReject, setSelectedReject] = useState('1')
    const [remark, setRemark] = useState('')

    const intl = useIntl();
    const suhbeader = useSubheader();
    suhbeader.setTitle('บริการลูกค้า / แจ้งเคลมประกันภัยอื่นๆ / แก้ไข');
    const history = useHistory();
    const [currencyList, setCurrencyList] = useState([]);
    const [currency, setCurrency] = useState();
    const [relateList, setRelateList] = useState([]);
    const [relate, setRelate] = useState();
    const [paymentList, setPaymentList] = useState([]);
    const [payment, setPayment] = useState();
    const [paymentAmount, setPaymentAmount] = useState("")
    const [typeList, setTypeList] = useState([]);
    const [ClaimertypeList, setClaimerTypeList] = useState([{
        key: 'person',
        name: 'บุคคล'
    },
    {
        key: 'corperate',
        name: 'นิติบุคคล'
    }
    ]);
    const [role, setRole] = useState('')
    const [policyTypeList, setPolicyTypeList] = useState([])
    const [ClaimerTypeList2, setClaimerTypeList2] = useState([])
    const [saveState, setSaveState] = useState([])
    const [causeList, setCauseList] = useState([])
    const [allData, setAllData] = useState([])
    const [txtSearchPolicy, setTxtSearchPolicy] = useState("")
    const [refNo, setRefNo] = useState();
    const [insurranceType, setInsurranceType] = useState()
    const [claimerType, setClaimerType] = useState()
    const [policyType, setPolicyType] = useState()
    const [policy_type_detail, setPolicy_type_detail] = useState()
    const [policyNumber, setPolicyNumber] = useState()
    const [informerFirstName, setInformerFirstName] = useState()
    const [informerLastName, setInformerLastName] = useState()
    const [description, setDescription] = useState()
    const [claimerName, setClaimerName] = useState()
    const [claimerLastname, setClaimerLastname] = useState()
    const [mobileNumber, setMobileNumber] = useState()
    const [companyNumber, setCompanyNumber] = useState()
    const [email, setEmail] = useState()
    const [citizenNumber, setCitizenNumber] = useState()
    const [taxID, setTaxID] = useState()
    const [relation, setRelation] = useState()
    const [relationDetail, setRelationDetail] = useState()
    const [happenDate, setHappenDate] = useState()
    // const [happenDate1, setHappenDate1] = useState()
    const [cause, setCause] = useState()
    const [address, setAddress] = useState()
    const [addressDetail, setAddressDetail] = useState()
    const [addressDetail2, setAddressDetail2] = useState()
    const [contact, setContact] = useState()
    const [detail, setDetail] = useState()
    const [ref, setRef] = useState()
    const [amount, setAmount] = useState()
    const [claimerType2, setClaimerType2] = useState()
    const [showPolicyList, setshowPolicyList] = useState(false)
    const [policyList, setPolicyList] = useState([])
    const [provinceList, setProvinceList] = useState([])
    const [subDistrictList, setSubDistrictList] = useState([])
    const [districtList, setDistrictList] = useState([])
    const [claimNo, setClaimNo] = useState('')
    const [claimNoRef, setClaimNoRef] = useState('')
    const [claimStatus, setClaimStatus] = useState('')
    const [showPopup, setShowPopup] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false)
    const [showSearchPolicy, setShowSearchPolicy] = useState(false)
    const [searchPolicyNo, setSearchPolicyNo] = useState()
    const [showDetailPolicy, setShowDetailPolicy] = useState(false)
    const [policyData, setPolicyData] = useState([])

    const [showNotice, setShowNotice] = useState(false)
    const [tempFile, setTempFile] = useState()

    const [fileClaimList, setFileClaimList] = useState([])
    const [fileDocSignList, setFileDocSignList] = useState([])
    const [fileDocSignListOld, setFileDocSignListOld] = useState([])
    const [fileRejectList, setFileRejectList] = useState([])
    const [confirmCheck, setConfirmCheck] = useState([])
    const [confirmCheckApprove, setConfirmCheckApprove] = useState([])
    const [is_hadApprove, setIs_hadApprove] = useState(false)
    const [notCompleteCheck, setNotCompleteCheck] = useState(false)

    const [isPayment, setIsPayment] = useState(false)

    const [bank_account_number, setBank_account_number] = useState([])
    const [bank_account_name, setBank_account_name] = useState([])
    const [bank_account_trnasfer, setBank_account_trnasfer] = useState([])
    const [bank_account_branch, setBank_account_branch] = useState([])
    const [is_duplicate, setIs_duplicate] = useState(false)
    const [is_reference, setIs_reference] = useState(true)
    const [old_ref_no, setOld_ref_no] = useState(true)
    const [hrefLink, setHrefLink] = useState('')
    const [id, setId] = useState()
    const [isURLSearch, setURLSearch] = useState(false)

    const [province, setProvince] = useState('')
    const [district, setDistrict] = useState('')
    const [subDistrict, setSubdistrict] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const [isLoadingNoti, setIsLoadingNoti] = useState(false)

    const [isLoadingComplete1, setIsLoadingComplete1] = useState(false)

    const [isLoadingNotCompleteCheck1, setIsLoadingNotCompleteCheck1] = useState(false)

    const [isLoadingComplete2, setIsLoadingComplete2] = useState(false)

    const [isLoadingNotCompleteCheck2, setIsLoadingNotCompleteCheck2] = useState(false)

    const [isLoadingReject, setIsLoadingReject] = useState(false)

    const [isLoadingIIA, setIsLoadingIIA] = useState(false)

    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)

    const [isLoadingCopy, setIsLoadingCopy] = useState(false)

    const [isLoadingUndo, setIsLoadingUndo] = useState(false)
    const [file, setFile] = useState()

    const [isLoadingSearch, setIsLoadingSearch] = useState(false)

    const [isLoadingImage , setIsLoadingImage] = useState(false)
    const [isSend, setIsSend] = useState(false)

    const [otherDoc, setOtherDoc] = useState([])
    const search = useLocation().search;

    const [dataAddress, setDataAddress] = useState([])

    const [pageNumber, setPageNumber] = useState(0)
    const [addressTran, setAddressTran] = useState([])

    const [zipcode, setZipcode] = useState([])

    const [policyNumberFromApp, setPolicyNumberFromApp] = useState()

    const addressPerPage = 10
    const pageVisited = pageNumber * addressPerPage

    const displayAddress = addressTran.slice(pageVisited, pageVisited + addressPerPage).map((addressArr, index) => {
        return (<tr className="row-table"><td><p style={{ marginLeft: "15px", marginBottom: "0px" }}>ที่อยู่ {pageNumber >= 1 ? (pageVisited + 1) + index : index + 1} : {addressArr.address}</p></td></tr>)
    })

    useMemo(() => { setAddressTran(dataAddress) }, [dataAddress])

    const pageCount = Math.ceil(addressTran.length / addressPerPage)

    const [dataAddress1, setDataAddress1] = useState([])

    const [pageNumber1, setPageNumber1] = useState(0)
    const [addressTran1, setAddressTran1] = useState([])

    const [imageUrl, setImageUrl] = useState(null);
    const [previewedIndex, setPreviewedIndex] = useState(null);
    const [previewedFile, setPreviewedFile] = useState(null);

    const addressPerPage1 = 10
    const pageVisited1 = pageNumber1 * addressPerPage1

    const displayAddress1 = addressTran1.slice(pageVisited1, pageVisited1 + addressPerPage1).map((result) => {
        return (<tr>
            <td>{result.policy_peril_type}</td>
            <td>{result.description}</td>
            <td><NumberFormat value={result.org_sum_insured} displayType="text" thousandSeparator={true} decimalScale={2} /></td>
            <td><NumberFormat value={result.org_gross_premium} displayType="text" thousandSeparator={true} decimalScale={2} /></td>
        </tr>)
    })

    useMemo(() => { setAddressTran1(dataAddress1) }, [dataAddress1])

    const pageCount1 = Math.ceil(addressTran1.length / addressPerPage1)

    const AlertError = (message) => {
        Swal.fire("แจ้งเตือนระบบ !", message, "error");
    };

    const setPolicy = (data) => {
        if (!data) {
            return setPolicyList([])
        }
        const temp = data.split(',').filter(item => item != 'null')
        if (temp.length == 1) {
            setPolicyNumber(temp[0])
        } else {
            setshowPolicyList(true)
        }
        setPolicyList(temp)
    }

    useEffect(() => {
        getData()
        configRole()
    }, []);

    const configRole = () => {
        let role = localStorage.getItem('role')
        setRole(role)
    }

    const handleChosenRejected = (e) => {
        setSelectedReject(e.target.value)
    }
    const handleClosePopupRejected = () => {
        setFileUploadDocReject('')
        setShowRejectPopup(false)
        setSelectedReject('1')
        setIsLoadingReject(false)
    }

    const getType = async () => {
        let apiUrls = `${baseURL}/api/v1/type`;
        axios({
            method: "get",
            url: apiUrls,
        })
            .then(async (res) => {
                setTypeList(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getRelate = async () => {
        let apiUrls = `${baseURL}/api/v1/relate`;
        axios({
            method: "get",
            url: apiUrls,

        })
            .then(async (res) => {
                setRelateList(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getCurrency = async () => {
        let apiUrls = `${baseURL}/api/v1/currency`;
        axios({
            method: "get",
            url: apiUrls,
        })
            .then(async (res) => {
                setCurrencyList(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getPayment = async () => {
        let apiUrls = `${baseURL}/api/v1/payment`;
        axios({
            method: "get",
            url: apiUrls,
        })
            .then(async (res) => {
                setPaymentList(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getZipcode = async (zipcode, district, subDistrict, province) => {
        let apiUrls = `${baseURL}/api/v1/location/zipcode/${zipcode}`;
        axios({
            method: "get",
            url: apiUrls,
        })
            .then(async (res) => {
                if (res.data.length != 0) {
                    let temp = res.data.filter(e => e.id == Number(district))
                    setSubDistrictList(temp[0].subDistricts)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getProvince = async () => {
        let apiUrls = `${baseURL}/api/v1/location`;
        axios({
            method: "get",
            url: apiUrls,
        })
            .then(async (res) => {
                setProvinceList(res.data)

            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getDistrict = async (id) => {
        let apiUrls = `${baseURL}/api/v1/location/district2?id=${id}`;
        axios({
            method: "get",
            url: apiUrls,
        })
            .then(async (res) => {
                setDistrictList(res.data)

            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getSubDistrict = async (code) => {
        let apiUrls = `${baseURL}/api/v1/location/subdistrict/${code}`;
        axios({
            method: "get",
            url: apiUrls,
        })
            .then(async (res) => {
                setSubDistrictList(res.data)

            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getDistrict2 = async (code) => {
        let apiUrls = `${baseURL}/api/v1/location/district/${code}`;
        axios({
            method: "get",
            url: apiUrls,
        })
            .then(async (res) => {
                setDistrictList(res.data)

            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getPolicy = async (p) => {
        let apiUrls = `${baseURL}/api/v1/policy`;
        axios({
            method: "get",
            url: apiUrls,
        })
            .then(async (res) => {
                setPolicyTypeList(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const tryText = (data) => {
        try {
            return data.insurance_type.key
        } catch {
            return ' '
        }
    }

    const getData = async () => {
        let idSearch = true
        const ref = new URLSearchParams(search).get('ref_no');
        if (ref) {
            idSearch = false
            setURLSearch(true)
        }
        if (!props.location.state && !ref) {
            window.location.reload()

        }
        let apiUrls = idSearch ? `${baseURL}/api/v1/claim/${props.location.state.row.id}` : `${baseURL}/api/v1/claim/ref/${ref}`;
        axios({
            method: "get",
            url: apiUrls,
        })
            .then(async (res) => {
                console.log(res.data)
                setAllData(res.data)
                try {
                    setClaimNo(res.data.data.claim_no)
                    setClaimNoRef(res.data.data.claim_no)
                    setClaimerType(res.data.data.informer_Type)
                    setMobileNumber(res.data.data.mobile_number)
                    setCompanyNumber(res.data.data.telephone)
                    setEmail(res.data.data.email)
                    setCitizenNumber(res.data.data.citizenID)
                    setRelation(res.data.data.related ? res.data.data.related : ' ')
                    setClaimerLastname(res.data.data.claimer_lastName)
                    setClaimerName(res.data.data.claimer_firstName)
                    setInformerFirstName(res.data.data.informer_firstName)
                    setInformerLastName(res.data.data.informer_lastName)
                    setDescription(res.data.data.description)
                    setPolicyType(res.data.data.policyType ? res.data.data.policyType.key : ' ')
                    setPolicy_type_detail(res.data.data.policy_type_detail ? res.data.data.policy_type_detail : ' ')
                    setInsurranceType(tryText(res.data.data))
                    setClaimerType2(res.data.data.claimer_type)
                    setCause(res.data.data.clause)
                    setPolicy(res.data.data.policy_no)
                    setContact(res.data.data.contract)
                    setAddress(res.data.data.address)
                    setAddressDetail(res.data.data.address_detail)
                    setAddressDetail2(res.data.data.address_detail2)
                    setRelate(res.data.data.related ? res.data.data.related : ' ')
                    setRelationDetail(res.data.data.related_description ? res.data.data.related_description : ' ')
                    setPayment(res.data.data.payment)
                    setTaxID(res.data.data.taxID)
                    setHappenDate(res.data.data.incident_date.substring(0, 10))
                    setAmount(res.data.data.amount)
                    setRef(res.data.data.claim_ref_no)
                    setCurrency(res.data.data.currency)
                    setBank_account_number(res.data.data.bank_account_number)
                    setBank_account_name(res.data.data.bank_account_name)
                    setBank_account_trnasfer(res.data.data.bank_account_trnasfer)
                    setBank_account_branch(res.data.data.bank_account_branch)
                    getType()
                    getPolicy()
                    getClaimerType()
                    getCause()
                    getPayment()
                    getCurrency()
                    getRelate()
                    setFileRejectList(isJson(res.data.data.reject_file_path) ? fileRejectList => [...fileRejectList, JSON.parse(res.data.data.reject_file_path)] : [])
                    setFileDocSignList(isJson(res.data.data.approve_file_path) ? JSON.parse(res.data.data.approve_file_path) : [])
                    setFileDocSignListOld(isJson(res.data.data.old_approve_file_path) ? JSON.parse(res.data.data.old_approve_file_path) : [])
                    setClaimStatus(res.data.data.claim_status)
                    setId(res.data.data.id)
                    setProvince(res.data.data.province)
                    setDistrict(res.data.data.district)
                    setSubdistrict(res.data.data.sub_district)
                    setRemark(res.data.data.remark)
                    setPaymentAmount(res.data.data.payment_amount)
                    setZipcode(res.data.data.zipcode)
                    setProvince(res.data.data.province)
                    setDistrict(res.data.data.district)
                    setSubdistrict(res.data.data.sub_district)
                    getProvince()
                    getDistrict(res.data.data.district)
                    getZipcode(res.data.data.zipcode, res.data.data.district, res.data.data.sub_district, res.data.data.province)
                    setIs_duplicate(res.data.data.is_duplicate)
                    setIs_reference(res.data.data.is_reference)
                    setOld_ref_no(res.data.data.old_ref_no)
                    setHrefLink(`${webURL}/ClaimTransaction/?ref_no=${res.data.data.old_ref_no}`)
                    setFileData(res.data.data.file)
                    setFile(res.data.data.file)
                    setFileDataApprove(res.data.data.approve_file_path)
                    setPolicyNumberFromApp(res.data.data.policy_no_from_app)
                } catch (error) {
                    console.log(error)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getClaimerType = async (p) => {
        let apiUrls = `${baseURL}/api/v1/claimerType`;
        axios({
            method: "get",
            url: apiUrls,
        })
            .then(async (res) => {
                setClaimerTypeList2(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getCause = async (p) => {
        let apiUrls = `${baseURL}/api/v1/cause`;
        axios({
            method: "get",
            url: apiUrls,

        })
            .then(async (res) => {
                setCauseList(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getFile = async (item, type) => {
        console.log(item,'<==========')
        const token = localStorage.getItem('token');
        try {
            const response = await
                axios.get(`${baseURLV1}/upload/file/${item}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    responseType: 'blob'
                });

            let blobUrl = null;
            if(type.toLowerCase() == 'pdf'){
                const file = new Blob([response.data], { type: 'application/pdf' });
                blobUrl = URL.createObjectURL(file);
            }
            else {
                blobUrl = URL.createObjectURL(response.data);
                // const imageBlobUrl = URL.createObjectURL(response.data);
            }

            setImageUrl(blobUrl);
            window.open(blobUrl, "_blank");
        } catch (error) {
            console.error('Failed to fetch image:', error);
        }
    }

    const handleButtonClick = async (item) => {

        if(item.includes('.pdf'))
        {
            await getFile(item, 'pdf'); 
        } else {
            await getFile(item, 'image'); 
        }

        // if (previewedFile === item) {
        //   setPreviewedFile(null);
        //   setImageUrl(null);
        // } else {
        //   await getImage(item); 
          
          
        // //   setPreviewedFile(item); 
    
        // }
      };
    

    const setFileData = (data) => {
        try {
            let newArray = isJson(data) ? JSON.parse(data) : []
            let newData = newArray.filter((d) => d.is_edoc == true)
            if (newData.length > 0) setFileClaimList(newData)
            else {
                setFileClaimList(newArray)
                console.log(newArray,'========')
            }
            if (newArray) {
                newArray.map(item => {
                    let demo = {
                        "subDoctype": item.subDoctype,
                        "Listitem": item.Listitem,
                        "is_required": item.Listitem.length == 0 || item.Listitem == '[]'
                    }
                    setConfirmCheck(confirmCheck => [...confirmCheck, demo])
                    setSaveState(saveState => [...saveState, demo])
                })
            }
        } catch (error) {
            console.log(error)
        }

    }
    
    const setFileDataApprove = (data) => {
        if (isJson(data) && data) {
            setIs_hadApprove(true)
            let temp = JSON.parse(data)
            let otherDocArr = []
            temp.map(item => {
                let demo = {
                    "file_title": item.file_title,
                    "file_attachment": item.file_attachment,
                    "file_attachment_list": item.file_attachment_list,
                    "file_request_lists": item.file_request_lists,
                    "is_additional": item.is_additional,
                    "is_required": false
                }
                if (item.file_title.search(/เอกสารอื่นๆ/) != -1) otherDocArr.push(demo)
                setConfirmCheckApprove(confirmCheckApprove => [...confirmCheckApprove, demo])
            })
            setOtherDoc(otherDocArr)
        }

    }
    function isJson(str) {
        try {
            if (!str) return false
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const onSubmit = async () => {
        //return console.log('check')
        /*if (insurranceType === undefined || insurranceType === "") {
            return AlertError("กรุณากรอกประเภทประกันภัย");
        }
        if (claimerType === undefined || claimerType === "") {
            return AlertError("กรุณากรอกประเภทผู้เอาประกัน");
        }

        if (policyType === undefined || policyType === "") {
            return AlertError("กรุณากรอกประเภทกรมธรรม์");
        }*/

        /*if (policyNumber === undefined || policyNumber === "") {
            return AlertError("กรุณาเลือกหมายเลขกรมธรรม์");
        }*/

        /*if (claimerType2 === undefined || claimerType2 === "") {
            return AlertError("กรุณาเลือกประเภทผู้ทำประกัน");
        }

        if (claimerName === undefined || claimerName === "") {
            return AlertError("กรุณากรอกชื่อผู้ทำประกัน");
        }

        if (claimerType2 === undefined || claimerType2 === "") {
            return AlertError("ประเภทผู้ทำประกัน");
        }

        if (mobileNumber === undefined || mobileNumber === "") {
            return AlertError("กรุณากรอกเบอร์โทรศัพท์");
        }

        if (companyNumber === undefined || companyNumber === "") {
            return AlertError("กรุณากรอกเบอร์โทรศัพท์ที่ทำงาน");
        }

        if (email === undefined || email === "") {
            return AlertError("กรุณากรอกอีเมล");
        }
        if (citizenNumber) {
            if (citizenNumber === undefined || citizenNumber === "") {
                return AlertError("กรุณากรอกเลขประจำตัวประชาชน");
            }
            if (claimerLastname === undefined || claimerLastname === "") {
                return AlertError("กรุณากรอกนามสกุลผู้ทำประกัน");
            }
        }

        if (taxID) {
            if (taxID === undefined || taxID === "") {
                return AlertError("กรุณากรอกเลขประจำตัวผู้เสียภาษี");
            }
        }
        if (relate === undefined || relate === "") {
            return AlertError("กรุณากรอกความสัมพันธ์");
        }

        if (happenDate === undefined || happenDate === "") {
            return AlertError("กรุณากรอกวันที่เกิดเหตุ");
        }

        if (cause === undefined || cause === "") {
            return AlertError("กรุณาเลือกสาเหตุ");
        }

        if (address === undefined || address === "") {
            return AlertError("กรุณากรอกสถานที่เกิดเหตุ");
        }

        if (detail === undefined || detail === "") {
            return AlertError("กรุณาระบุรายละเอียด");
        }

        if (amount === undefined || amount === "") {
            return AlertError("กรุณาระบุจำนวนสินไหม");
        }
        if (payment === undefined || payment === "") {
            return AlertError("กรุณาระบุวิธีรับเงิน");
        }

        if (contact === undefined || contact === "") {
            return AlertError("กรุณาระบุรายละเอียดสำหรับการติดต่อกลับ");
        }*/

        /*if (claimNo === undefined || claimNo === "") {
            return AlertError("กรุณาระบุหมายเลขเคลม");
        }*/

        if (district === undefined || district === "") {
            return AlertError("กรุณาเลือกอำเภอ");
        }

        if (subDistrict === undefined || subDistrict === "") {
            return AlertError("กรุณาเลือกตำบล");
        }

        var events = {
            //claim_no: claimNo,
            policy_no: policyNumber,
            type: insurranceType,
            informer_Type: claimerType,
            informer_firstName: informerFirstName,
            informer_lastName: informerLastName,
            policy_type: policyType,
            policy_type_detail: policy_type_detail,
            claimer_firstName: claimerName,
            claimer_lastName: claimerLastname,
            mobile_number: mobileNumber,
            telephone: companyNumber,
            email: email,
            citizenID: citizenNumber,
            taxID: taxID,
            related: relate,
            incident_date: moment(happenDate).format("YYYY-MM-DD"),
            clause: cause,
            place: address ? address : ' ',
            description: description,
            amount: amount,
            payment: payment,
            contract: contact,
            related_description: relationDetail,
            address: address,
            address_detail: addressDetail,
            address_detail2: addressDetail2,
            province: province,
            district: district,
            sub_district: subDistrict,
            remark: remark,
            zipcode: zipcode,
            province: province,
            district: district,
            sub_district: subDistrict,
        };
        // console.log(events)
        let apiUrls = `${baseURL}/api/v1/claim/${id}`;
        let token = localStorage.getItem("token");
        await axios({
            method: "put",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: events,
        }).then((res) => {
            Swal.fire(
                "อัพเดทสำเร็จ !",
                "ทำการบันทึกรายการสำเร็จ",
                "success"
            ).then(async () => {
                await getData()
                window.location.reload();
            })
        }).catch((err) => {
            Swal.fire(
                "เกิดข้อผิดพลาด !",
                "กรุณาตรวจสอบรายการ ",
                "error"
            );
        });
    };

    const handleSendNoticeReject = async () => {
        if (selectedReject == '2' && !fileUploadDocReject) {
            return Swal.fire({
                title: "",
                text: "กรุณาแนบเอกสารหนังสือชี้แจงลูกค้า !",
                icon: "warning",
            })
        }
        Swal.fire({
            title: "",
            text: selectedReject == 2 ? 'ยืนยันปฏิเสธความคุ้มครอง ?' : 'ยืนยันไม่พบกรมธรรม์ ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            showLoaderOnConfirm: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (selectedReject == '') {
                    return
                }
                let apiUrls = `${baseURL}/api/v1/claim/document-rejected/${id}`;
                let token = localStorage.getItem("token");
                var data = null
                if (selectedReject == '2') {
                    let upload = await uploadFile(fileUploadDocReject)
                    if (upload) {
                        upload = JSON.parse(upload)
                        setTempFile(upload.data)
                        let file = JSON.parse(upload.data)
                        data = {
                            "file_title": "สำเนาเอกสารปฏิเสธกรมธรรม์",
                            "file_lists": file[0]
                        }
                    }
                }
                const objString = JSON.stringify(data)
                await axios({
                    method: "put",
                    url: apiUrls,
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    data: {
                        reject_type: `${selectedReject}`,
                        reject_file_path: objString
                    },
                }).then(async (res) => {
                    setShowRejectPopup(false)
                    setTempFile('')
                    setSelectedReject('')
                    Swal.fire({
                        title: selectedReject == 2 ? 'ปฏิเสธความคุ้มครองสำเร็จ !' : 'ไม่พบกรมธรรม์สำเร็จ !',
                        text: selectedReject == 2 ? '' : '',
                        icon: 'success',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload()
                        }
                    })

                }).catch((err) => {
                    setFileUploadDocReject('')

                    setShowRejectPopup(false)
                    console.log(err);

                });
            }
        })


    }

    const check = () => {
        if (!policyNumber) {
            return AlertError("กรุณาเลือกหมายเลขกรมธรรม์");
        } else {
            setShowPopup(true)
        }

    }

    const handleCreateClaimNo = async () => {
        var events = {
            claim_no: claimNo,
            policy_no: policyNumber
        }
        let apiUrls = `${baseURL}/api/v1/claim/iia/${id}`;
        if (!claimNo) return AlertError("กรุณาเลือกหมายเลขเคลมจาก IIA");
        let token = localStorage.getItem("token");
        await axios({
            method: "put",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: events,
        })
            .then(async (res) => {
                setIsLoadingIIA(false)
                setShowPopup(false)
                setIsSubmit(true)
                setClaimNoRef(claimNo)
                localStorage.setItem('save', JSON.stringify({ claimNo: claimNo, ref: ref }))
                Swal.fire(
                    "",
                    "ระบุเลขเคลมสำเร็จ !",
                    "success"
                );
            })
            .catch((err) => {

                Swal.fire(
                    "กรุณาระบุเลขเคลมใหม่ !",
                    "กรุณาระบุเลขเคลมใหม่ ",
                    "warning"
                );

                setIsLoadingIIA(false)
            });
    }

    const handleSave = async () => {
        await Swal.fire({
            title: '',
            text: "ยืนยันอัพเดทข้อมูล ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            showLoaderOnConfirm: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                await onSubmit()
                setIsLoadingUpdate(false)
            } else {
                setIsLoadingUpdate(false)
            }
        })
    }

    const CreateDuplicate = async () => {

        Swal.fire({
            title: "",
            text: 'ยืนยันการสร้างหมายเลขเคลมใหม่',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            showLoaderOnConfirm: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                let token = localStorage.getItem("token");
                var requestOptions = {
                    method: "POST",
                    redirect: "follow",
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                };
                await fetch(`${baseURL}/api/v1/claim/duplicate/${id}`, requestOptions)
                    .then(res => res.json())
                    .then((response) => {
                        if (response.success) {
                            setIsLoadingCopy(false)
                            Swal.fire({
                                title: '',
                                text: ' ขอเอกสารเพิ่มสำเร็จ !',
                                icon: 'success'
                            }).then(async (result) => {
                                if (result.isConfirmed) {
                                    window.open(`/ClaimTransaction?ref_no=${response.data.claim_ref_no}`);
                                    window.location.reload()
                                }
                            })
                        } else {
                            setIsLoadingCopy(false)
                            Swal.fire({
                                title: '',
                                text: 'ลองทำรายการใหม่อีกครั้ง !',
                                icon: 'error'
                            })
                        }
                    })
                    .catch((error) => {
                        return error
                    });
            } else {
                setIsLoadingCopy(false)
            }
        })

    }

    const handleUndo = async () => {
        Swal.fire({
            title: '',
            text: "ยืนยันล้างการกรอกข้อมูล ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            showLoaderOnConfirm: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                getData()
                setIsLoadingUndo(false)
            } else {
                setIsLoadingUndo(false)
            }

        })

    }

    const pushNotCorrect2 = async (check, data) => {
        let temp = confirmCheck
        if (check) {
            await temp.map(item => {
                if (item.subDoctype == data.subDoctype) {
                    item.is_required = true
                }
            })
            setConfirmCheck(temp)
        } else {
            await temp.map(item => {
                if (item.subDoctype == data.subDoctype) {
                    item.is_required = false
                }
            })
            setConfirmCheck(temp)
        }
    }


    const pushApproveFile = async (check, data) => {
        let temp = confirmCheckApprove
        let notfound = true

        if (check) {
            await temp.map(item => {
                if (item.file_title == data.file_title) {
                    notfound = false
                    item.file_attachment = data.file_attachment ? data.file_attachment : []
                    item.file_attachment_list = data.file_attachment_list ? data.file_attachment_list : []
                    item.is_additional = data.file_title == 'หนังสือลงนาม' ? false : true
                    item.is_required = true
                }
            })
            if (notfound) {
                temp.push({
                    "file_title": data.file_title,
                    "file_attachment": data.file_attachment ? data.file_attachment : [],
                    "file_attachment_list": data.file_attachment_list ? data.file_attachment_list : [],
                    "file_request_lists": [],
                    "is_additional": data.file_title == 'หนังสือลงนาม' ? false : true,
                    "is_required": true
                })
                setConfirmCheckApprove(temp)
            }
            setConfirmCheckApprove(temp)
        } else {
            await temp.map(item => {
                if (item.file_title == data.file_title) {
                    item.is_additional = true
                    item.is_required = false
                }
            })
            setConfirmCheckApprove(temp)
        }
    }

    const handleNotComplete = () => {
        const checkConfirm = confirmCheck.filter(c => c.is_required == true)
        if (checkConfirm.length == 0) {
            if (!requireRemark2) {
                return Swal.fire({
                    title: '',
                    text: 'กรุณาเลือกเอกสาร !',
                    icon: 'warning'
                })
            } else {
                Swal.fire({
                    title: '',
                    text: "ยืนยันเอกสารไม่ครบ ? ",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'ตกลง',
                    cancelButtonText: 'ยกเลิก',
                    showLoaderOnConfirm: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        let temp0 = requireRemark2 ? [{
                            "subDoctype": `อื่นๆ(${requireRemarkTxt2})`,
                            "Listitem": `[]`,
                            "is_required": true
                        }] : []
                        let temp = [
                            ...confirmCheck,
                            ...temp0
                        ]
                        let send = JSON.stringify(temp)
                        var events = {
                            "file": send
                        }
                        let apiUrls = `${baseURL}/api/v1/claim/document-notcomplete/${id}`;
                        let token = localStorage.getItem("token");

                        axios({
                            method: "put",
                            url: apiUrls,
                            headers: {
                                Accept: "application/json",
                                Authorization: `Bearer ${token}`,
                            },
                            data: events,
                        })
                            .then(async (res) => {
                                Swal.fire({
                                    title: '',
                                    text: 'สร้างเลขเคลมใหม่สำเร็จ !',
                                    icon: 'success'
                                })
                                setIsSend(true)
                                // history.push({ pathname: '/ClaimManagement' })
                                window.location.reload()
                            })
                            .catch((err) => {
                                setIsSend(true)

                                Swal.fire({
                                    title: "",
                                    text: "เกิดข้อผิดพลาด !",
                                    icon: "error"
                                });
                            });
                    }
                })
            }

        } else {
            Swal.fire({
                title: '',
                text: "ยืนยันเอกสารไม่ครบ ? ",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก',
                showLoaderOnConfirm: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    let temp0 = requireRemark2 ? [{
                        "subDoctype": `อื่นๆ(${requireRemarkTxt2})`,
                        "Listitem": `[]`,
                        "is_required": true
                    }] : []
                    let temp = [
                        ...confirmCheck,
                        ...temp0
                    ]
                    let send = JSON.stringify(temp)
                    var events = {
                        "file": send
                    }
                    let apiUrls = `${baseURL}/api/v1/claim/document-notcomplete/${id}`;
                    let token = localStorage.getItem("token");

                    axios({
                        method: "put",
                        url: apiUrls,
                        headers: {
                            Accept: "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                        data: events,
                    })
                        .then(async (res) => {
                            setIsSend(true)

                            Swal.fire({
                                title: '',
                                text: ' ขอเอกสารเพิ่มสำเร็จ !',
                                icon: 'success'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    window.location.reload()
                                }
                            })
                            // history.push({ pathname: '/ClaimManagement' })

                        })
                        .catch((err) => {
                            setIsSend(true)

                            Swal.fire({
                                title: "",
                                text: "เกิดข้อผิดพลาด !",
                                icon: "error"
                            });
                        });
                }
            })
        }
    }

    const handleComplete = (text) => {
        setIsLoadingComplete2(false)
        setIsLoadingComplete1(false)
        Swal.fire({
            title: '',
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoadingComplete2(false)
                var events = {
                    "claim_no": claimNo,
                    "policy_no": policyNumber,
                }
                let apiUrls = `${baseURL}/api/v1/claim/document-complete/${id}`;
                let token = localStorage.getItem("token");
                axios({
                    method: "put",
                    url: apiUrls,
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    data: events,
                })
                    .then(async (res) => {
                        await Swal.fire({
                            title: "",
                            text: "ยืนยันเอกสารครบสำเร็จ !",
                            icon: "success"
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.reload()
                            }
                        })
                    })
                    .catch((err) => {
                        Swal.fire({
                            title: "",
                            text: "เกิดข้อผิดพลาด !",
                            icon: "error"
                        }).then((result) => {
                            if (result.isConfirmed) {
                                setIsLoadingComplete1(false)
                            }
                        })


                    });
            } else {
                setIsLoadingComplete2(false)
                setIsLoadingComplete1(false)
            }
        })
    }

    const handleCloseCreateClaimNo = () => {
        setShowPopup(false)
        setClaimNo("")
    }
    const handleClose = () => {
        setShowNotice(false)
        setRequireRemark(false)
        setFileUploadDocSign('')
        setIsLoadingNoti(false)
    };

    const uploadFile = async (files) => {
        var formdata = new FormData();
        let token = localStorage.getItem("token");
        if (files.length < 1) {
            Swal.fire({
                title: "",
                text: "กรุณาแนบเอกสารหนังสือชี้แจงลูกค้า",
                icon: "warning",
            })
            return false
        }

        for (let index = 0; index < files.length; index++) {
            formdata.append("multi-files", files[index], files[index].name);
        }

        var requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        return fetch(`${baseURL}/api/v1/upload/multiple-upload`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                return result
            })
            .catch((error) => {
                return error
            });
    };

    const handleCheckPayment = async () => {
        let apiUrls = `${baseURL}/api/v1/claim/document-checkpayment/${id}`;
        axios({
            method: "get",
            url: apiUrls,
            headers: {
                Accept: "application/json",
            }
        }).then(async (res) => {
            setPaymentAmount(res.data.payment_amount)
            setShowNotice(true)
            setIsPayment(true)
        }).catch((err) => {
            if (err.response) {
                Swal.fire({
                    title: "",
                    text: !err.response.data.message ? "เกิดข้อผิดพลาด !" : err.response.data.message,
                    icon: "error"
                });
                setIsLoadingNoti(false)
            }
        })
    }

    const handleSendNoticeApprove = async () => {
        let apiUrls = `${baseURL}/api/v1/claim/document-approved/${id}?is_payment=${isPayment}`;
        let sendData = []

        let upload = await uploadFile(fileUploadDocSign)
        if (upload) {
            upload = JSON.parse(upload)
            var file_attachment = JSON.parse(upload.data)
            let findOldAttachment = fileDocSignList.find((r) => r.file_title == 'หนังสือลงนาม')
            var fileList = []
            if (findOldAttachment && findOldAttachment.file_attachment) {
                if (findOldAttachment.file_attachment_list.length > 0) {
                    fileList = findOldAttachment.file_attachment_list
                }
                fileList.push(findOldAttachment.file_attachment)
            }
            let data = {
                "file_title": "หนังสือลงนาม",
                "file_attachment": file_attachment[0], //แนบไฟล์จากหลังบ้าน
                "file_attachment_list": fileList,
                "file_request_lists": [], // frontend upload กลับมา
                "is_additional": false, //เอกสารหลัก
                "is_required": true
            }
            sendData.push(data)
            pushApproveFile(true, {
                "file_title": "หนังสือลงนาม",
                "file_attachment": file_attachment[0],
                "file_attachment_list": fileList,
            })


            if (requireCopyCard) {
                let data = {
                    "file_title": "สำเนาบัตรประจำตัวประชาชน ของ \"ผู้เอาประกันภัย\"",
                    "file_attachment": [],
                    "file_request_lists": [],
                    "is_additional": true,
                    "is_required": true

                }
                sendData.push(data)
            }

            if (requireCertificate) {
                let data = {
                    "file_title": "สำเนาหนังสือรับรองบริษัทฯ",
                    "file_attachment": [],
                    "file_request_lists": [],
                    "is_additional": true,
                    "is_required": true

                }
                sendData.push(data)
            }

            if (requireBookBank) {
                let data = {
                    "file_title": "สำเนาสมุดบัญชีเงินฝาก \"ผู้เอาประกันภัย\" สำหรับรับโอนเงินค่าสินไหมทดแทน",
                    "file_attachment": [],
                    "file_request_lists": [],
                    "is_additional": true,
                    "is_required": true

                }
                sendData.push(data)
            }

            if (requireCopy20) {
                let data = {
                    "file_title": "สำเนา ภ.พ.20",
                    "file_attachment": [],
                    "file_request_lists": [],
                    "is_additional": true,
                    "is_required": true

                }
                sendData.push(data)
            }

            const objString = JSON.stringify(sendData)

            Swal.fire({
                title: '',
                text: "ยืนยันแจ้งผลพิจารณา ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก',
                showLoaderOnConfirm: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    axios({
                        method: "put",
                        url: apiUrls,
                        headers: {
                            Accept: "application/json",
                        },
                        data: {
                            approve_file_path: is_hadApprove ? JSON.stringify(confirmCheckApprove) : objString
                        },
                    })
                        .then(async (res) => {
                            setShowNotice(false)
                            setFileUploadDocSign('')
                            setConfirmCheckApprove([])
                            Swal.fire({
                                title: '',
                                text: 'แจ้งผลพิจารณาสำเร็จ !',
                                icon: 'success'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    window.location.reload()
                                }
                            })
                            // history.push({ pathname: '/ClaimManagement' })
                        })
                        .catch((err) => {
                            if (err.response) {
                                Swal.fire({
                                    title: "",
                                    text: !err.response.data.message ? "เกิดข้อผิดพลาด !" : err.response.data.message,
                                    icon: "error"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        setIsLoadingNoti(false)
                                    }
                                })

                            }
                            setFileUploadDocSign('')
                            setConfirmCheckApprove([])
                            setShowNotice(false)
                        });
                }
            })
        }
    }

    const searchPolicy = async (data) => {
        let apiUrls = `${baseURL}/api/v1/claim/search/policy?policy_no=${encodeURI(data)}`;
        return await axios({
            method: "get",
            url: apiUrls,
        }).then(async (res) => {
            return res.data.data
        }).catch((err) => {
            return []
        });
    };

    const handleSearchPolicyNo = async () => {

        setIsLoadingSearch(true)

        if (!searchPolicyNo) {
            Swal.fire("", "กรุณากรอกเลขกรมธรรม์", "error").then(result => {
                if (result.isConfirmed) {
                    setIsLoadingSearch(false)
                }
            })
        };

        let res = await searchPolicy(searchPolicyNo)
        if (res) {
            setPolicyNumber(res.policy_no)
            setIsLoadingSearch(false)
        }
        let oldData = policyList.filter((r) => r != res.policy_no)
        setPolicyList([...oldData, res.policy_no])
        setShowSearchPolicy(false)
        setSearchPolicyNo("")
        if (res.length != 0) {
            Swal.fire(
                "",
                "ค้นหาเลขกรมธรรม์สำเร็จ !",
                "success"
            ).then(result => {
                if (result.isConfirmed) {
                    setIsLoadingSearch(false)

                }
            })
        } else {
            Swal.fire(
                "แจ้งเตือนระบบ !",
                "ไม่พบเลขกรมธรรม์ที่ท่านค้นหา",
                "info"
            ).then(result => {
                if (result.isConfirmed) {
                    setIsLoadingSearch(false)

                }
            })
        }
    }

    const handleCloseSearchPolicyNo = () => {
        setShowSearchPolicy(false)
        setSearchPolicyNo("")
    }

    const handleShowDetailPolicy = async () => {
        if (policyData) {
            let findData = policyData.policy_no == policyNumber ? true : false
            if (findData == true) {
                setShowDetailPolicy(true)
                setIsLoading(true)
            } else if (findData == false) {
                setIsLoading(true)
            }
        }
        let res = await searchPolicy(policyNumber)
        if (res.length == 0) {
            setIsLoading(false)
            return Swal.fire(
                "แจ้งเตือนระบบ !",
                "ไม่พบเลขกรมธรรม์ที่ท่านค้นหา",
                "info"
            );
        }
        setPolicyData(res)
        if (res.claimer_address != undefined) {
            setDataAddress(res.claimer_address.slice(0, 10000))
            setDataAddress1(res.policy_peril.slice(0, 10000))
        }
        setShowDetailPolicy(true)
        setIsLoading(false)
    }

    const handleCloseDetailPolicy = () => {
        setShowDetailPolicy(false)
    }

    const handleChangeAddressInModal = ({ selected }) => {
        setPageNumber(selected)
    }
    const handleChangeAddressInModal1 = ({ selected }) => {
        setPageNumber1(selected)
    }

    const handleUploadPdfOnly = (file, isTypeReject = false) => {
        if (isTypeReject) {
            if (file && file[0].type == "application/pdf") {
                setFileUploadDocReject(file)
            } else {
                setFileUploadDocReject('')
                Swal.fire({
                    title: "แจ้งเตือน",
                    text: "กรุณาอัพชนิดไฟล์ทีเป็น PDF เท่านั้น !",
                    icon: "warning"
                });
            }
        }
        else {
            if (file && file[0].type == "application/pdf") {
                setFileUploadDocSign(file)
            } else {
                setFileUploadDocSign('')
                Swal.fire({
                    title: "แจ้งเตือน",
                    text: "กรุณาอัพชนิดไฟล์ทีเป็น PDF เท่านั้น !",
                    icon: "warning"
                });
            }
        }
    }

    const handleCloseNotComplease = () => {
        setNotCompleteCheck(false)
        setRequireRemark2(false)
        setIsLoadingNotCompleteCheck2(false)
        setRequireRemarkTxt2('')
        setIsLoadingNotCompleteCheck1(false)
        console.log(saveState)
        try {
            setConfirmCheck([])
            setFileClaimList(isJson(file) ? JSON.parse(file) : [])
            if (isJson(file)) {
                let temp = JSON.parse(file)
                temp.map(item => {
                    let demo = {
                        "subDoctype": item.subDoctype,
                        "Listitem": item.Listitem,
                        "is_required": item.Listitem.length == 0 || item.Listitem == '[]'
                    }
                    setConfirmCheck(confirmCheck => [...confirmCheck, demo])
                    setSaveState(saveState => [...saveState, demo])
                })
            }
        } catch (error) {
            console.log(error)
        }
        
        // clear all
        //pushNotCorrect2Clear()
    }
    console.log(fileClaimList);

    return (
        <>
            <Card>
                <CardHeader>

                    <div className="row w-100">

                        <div className="col" style={{ display: "flex", alignItems: "center", marginTop: '15px' }}><h3>{`Claim No. IIA: ${claimNo ? claimNo : 'wait for create'}`}</h3></div>

                        {(is_duplicate) && <div className="col my-8 font-weight-bold" style={{ textAlign: "right" }}>
                            <a href={hrefLink} target="_blank">Old Reference No : {old_ref_no}</a>
                        </div>}

                        <div className="w-100"></div>

                        <div className="col form-inline" style={{ marginBottom: "5px" }}>
                            <p style={{ fontSize: 18 }}>
                                สถานะ : {claimStatus == '01' ? status[0].description : claimStatus == '02' ? status[1].description : claimStatus == '03' ? status[2].description : claimStatus == '04' ? status[3].description : claimStatus == '04.1' ? 'ส่งเอกสารลงนาม' : claimStatus.includes('05') ? status[4].description : claimStatus == '06.1' ? status[5].description : claimStatus == '06.2' ? status[6].description : ''}
                            </p>
                        </div>


                    </div>

                </CardHeader>
                <CardBody>

                    <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "104018", defaultMessage: "Reference No." })}
                        </p>
                        <input
                            disabled
                            name="item_name"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={ref}
                            onChange={(e) => setRefNo(e.target.value)}
                        />
                    </div>

                    <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            {intl.formatMessage({ id: "###", defaultMessage: "ประเภทประกันภัย" })}:{" "}
                        </p>
                        <select
                            disabled={!(!claimNoRef && !isSubmit) || is_reference}
                            as="select"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={insurranceType}
                            onChange={e => setInsurranceType(e.target.value)}
                        >
                            <option value="">{intl.formatMessage({ id: "###", defaultMessage: "โปรดเลือก" })}</option>
                            {typeList &&
                                typeList.map((result, index) => {
                                    return (
                                        <option key={index} value={result.key}>
                                            {result.name}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>

                    <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "104018", defaultMessage: "รายละเอียดเพิ่มเติม" })}
                        </p>
                        <input
                            
                            name="item_name"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={policy_type_detail}
                            onChange={(e) => setPolicy_type_detail(e.target.value)}
                        />
                    </div>

                    <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "###", defaultMessage: "ประเภทผู้เอาประกันภัย" })}:{" "}
                        </p>
                        <select
                            as="select"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={claimerType}
                            onChange={e => setClaimerType(e.target.value)}
                            disabled={is_reference}

                        >
                            <option value="">{intl.formatMessage({ id: "###", defaultMessage: "โปรดเลือก" })}</option>
                            {ClaimertypeList &&
                                ClaimertypeList.map((result, index) => {
                                    return (
                                        <option key={index} value={result.key}>
                                            {result.name}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>

                    <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            {intl.formatMessage({ id: "###", defaultMessage: "ประเภทกรมธรรม์" })}:{" "}
                        </p>
                        <select
                            disabled={!(!claimNoRef && !isSubmit) || is_reference}
                            as="select"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={policyType}
                            onChange={e => setPolicyType(e.target.value)}
                        >
                            <option value="">{intl.formatMessage({ id: "###", defaultMessage: "โปรดเลือก" })}</option>
                            {policyTypeList &&
                                policyTypeList.map((result, index) => {
                                    return (
                                        <option key={index} value={result.key}>
                                            {result.name}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>

                    <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "104018", defaultMessage: "เลขกรมธรรม์ (From App)" })}
                        </p>
                        <input

                            name="policy_no_from_app"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={policyNumberFromApp}
                            // onChange={(e) => setPolicy_type_detail(e.target.value)}
                            disabled
                        />
                    </div>

                    <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            เลขกรมธรรม์:
                        </p>
                        <select
                            as="select"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            disabled={!(!claimNoRef && !isSubmit) || is_reference}
                            value={policyNumber}
                            onChange={e => setPolicyNumber(e.target.value)}
                        >
                            <option value="">โปรดเลือก</option>
                            {policyList &&
                                policyList.map((result, index) => {
                                    return (
                                        <option key={index} value={result}>
                                            {result}
                                        </option>
                                    );
                                })}
                        </select>
                        {((!claimNoRef && !isSubmit) || policyNumber == "") && <div>
                            {(claimStatus != "06.1" && claimStatus != '06.2') ? <IconButton aria-label="search" className={classes.extendedIcon} onClick={() => setShowSearchPolicy(true)}>
                                <SearchIcon fontSize="large" />
                            </IconButton> : ""} </div>}
                        {policyNumber && isLoading ?
                            <div>
                                <Button className="ml-2" variant="secondary" >
                                    <i className="fa fa-spinner fa-spin"></i> รอสักครู่...
                                </Button>
                            </div> :
                            policyNumber && <Button className="ml-2" variant="secondary" onClick={() => handleShowDetailPolicy()}> ดูข้อมูลกรมธรรม์ </Button>
                        }
                    </div>

                    <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "###", defaultMessage: "ผู้แจ้งเคลม" })}:{" "}
                        </p>
                        <select
                            as="select"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={claimerType2}
                            onChange={e => setClaimerType2(e.target.value)}
                            disabled={is_reference}
                        >
                            <option value="">{intl.formatMessage({ id: "###", defaultMessage: "โปรดเลือก" })}</option>
                            {ClaimerTypeList2 &&
                                ClaimerTypeList2.map((result, index) => {
                                    return (
                                        <option key={index} value={result.keyword}>
                                            {result.name}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>

                    <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            {intl.formatMessage({ id: "104018", defaultMessage: "ชื่อผู้เอาประกัน" })}
                        </p>
                        <input
                            name="item_name"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={claimerName}
                            disabled={!(!claimNoRef && !isSubmit) || is_reference}
                            onChange={(item_name) => setClaimerName(item_name.target.value)}
                        />
                    </div>

                    <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            นามสกุลผู้เอาประกัน
                        </p>
                        <input
                            name="claimerLastname"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={claimerLastname}
                            disabled={!(!claimNoRef && !isSubmit) || is_reference}
                            onChange={(e) => setClaimerLastname(e.target.value)}
                        />
                    </div>

                    {claimerType2 == "2" ?
                        <>
                            <div className="form-inline" style={{ marginBottom: "5px" }}>
                                <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                                    ชื่อผู้แจ้งเคลม
                                </p>
                                <input
                                    name="informerFirstName"
                                    maxLength="100"
                                    style={{
                                        height: "38px",
                                        width: "530px",
                                        backgroundColor: "#F4F6F9",
                                        borderRadius: "5px",
                                        borderWidth: "0",
                                    }}
                                    value={informerFirstName}
                                    disabled={!(!claimNoRef && !isSubmit) || is_reference}
                                    onChange={(e) => setInformerFirstName(e.target.value)}
                                />
                            </div>
                            <div className="form-inline" style={{ marginBottom: "5px" }}>
                                <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                                    นามสกุลผู้แจ้งเคลม
                                </p>
                                <input
                                    name="informerLastName"
                                    maxLength="100"
                                    style={{
                                        height: "38px",
                                        width: "530px",
                                        backgroundColor: "#F4F6F9",
                                        borderRadius: "5px",
                                        borderWidth: "0",
                                    }}
                                    value={informerLastName}
                                    disabled={!(!claimNoRef && !isSubmit) || is_reference}
                                    onChange={(e) => setInformerLastName(e.target.value)}
                                />
                            </div>
                        </>
                        :
                        ""
                    }

                    {/*!taxID && <div className="form-inline" style={{ marginBottom: "5px" }}>

                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "104018", defaultMessage: "นามสกุลผู้เอาประกัน" })}
                        </p>
                        <input

                            name="item_name"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={claimerLastname}
                            onChange={(item_name) => setClaimerLastname(item_name.target.value)}
                            disabled={!(!claimNoRef && !isSubmit) || is_reference}
                        />
                    </div>*/}

                    <div className="form-inline" style={{ marginBottom: "5px" }}>

                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "104018", defaultMessage: "เบอร์โทรศัพท์มือถือ" })}
                        </p>
                        <input

                            name="item_name"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={mobileNumber}
                            onChange={(item_name) => setMobileNumber(item_name.target.value)}
                            disabled={is_reference}
                        />
                    </div>

                    <div className="form-inline" style={{ marginBottom: "5px" }}>

                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "104018", defaultMessage: "เบอร์โทรศํพท์สำนักงาน" })}
                        </p>
                        <input

                            name="item_name"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={companyNumber}
                            onChange={(item_name) => setCompanyNumber(item_name.target.value)}
                            disabled={is_reference}
                        />
                    </div>

                    <div className="form-inline" style={{ marginBottom: "5px" }}>

                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "104018", defaultMessage: "อีเมล" })}
                        </p>
                        <input

                            name="item_name"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={email}
                            onChange={(item_name) => setEmail(item_name.target.value)}
                            disabled={is_reference}
                        />
                    </div>

                    {citizenNumber && <div className="form-inline" style={{ marginBottom: "5px" }}>

                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "104018", defaultMessage: "เลขประจำตัวประชาชน" })}
                        </p>
                        <input

                            name="item_name"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={citizenNumber}
                            onChange={(item_name) => setCitizenNumber(item_name.target.value)}
                            disabled={is_reference}
                        />
                    </div>}

                    {taxID && <div className="form-inline" style={{ marginBottom: "5px" }}>

                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "104018", defaultMessage: "เลขประจำตัวผู้เสียภาษี" })}
                        </p>
                        <input

                            name="item_name"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={taxID}
                            onChange={(item_name) => setTaxID(item_name.target.value)}
                            disabled={is_reference}
                        />
                    </div>}

                    <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "###", defaultMessage: "ความสัมพันธ์กับผู้เอาประกัน" })}:{" "}
                        </p>
                        <select
                            as="select"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={relate}
                            onChange={e => setRelate(e.target.value)}
                            disabled={is_reference}
                        >
                            <option value="">{intl.formatMessage({ id: "###", defaultMessage: "โปรดเลือก" })}</option>
                            {relateList &&
                                relateList.map((result, index) => {
                                    return (
                                        <option key={index} value={result.key}>
                                            {result.name}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>

                    <div className="form-inline" style={{ marginBottom: "5px" }}>

                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "104018", defaultMessage: "อื่นๆ โปรดระบุ" })}
                        </p>
                        <input

                            name="remark"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={relationDetail}
                            onChange={(e) => setRelationDetail(e.target.value)}
                        />
                    </div>

                    <div className="form-inline" style={{ marginBottom: '5px' }}>
                        <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>

                            {intl.formatMessage({ id: "###", defaultMessage: "วันที่เกิดเหตุ" })}:{' '}
                        </p>

                        <DatePicker
                            locale="th"
                            disabled={!(!claimNoRef && !isSubmit) || is_reference}
                            placeholderText="วว/ดด/ปปปป"
                            dateFormat="dd/MM/yyyy"
                            className="field-date2"
                            value={moment(happenDate).format("DD/MM/YYYY")}
                            onChange={date => {
                                setHappenDate(date)
                            }}
                        />


                        {/* <TextField
                            disabled={!(!claimNoRef && !isSubmit) || is_reference}
                            id="DateFrom"
                            type="date"
                            label={intl.formatMessage({ id: "###", defaultMessage: "Date From" })}
                            className=" mb-3 mr-3"
                            value={happenDate}
                            style={{
                                height: '38px',
                                width: '185px',
                                backgroundColor: '#F4F6F9',
                                borderRadius: '5px',
                                borderWidth: '0'
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={name => {
                                setHappenDate(name.target.value)
                            }}
                        /> */}
                    </div>



                    <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "###", defaultMessage: "สาเหตุ" })}:{" "}
                        </p>
                        <select
                            disabled={!(!claimNoRef && !isSubmit) || is_reference}
                            as="select"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={cause}
                            onChange={e => setCause(e.target.value)}
                        >
                            <option value="">{intl.formatMessage({ id: "###", defaultMessage: "โปรดเลือก" })}</option>
                            {causeList &&
                                causeList.map((result, index) => {
                                    return (
                                        <option key={index} value={result.key}>
                                            {result.name}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>

                    <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "###", defaultMessage: "สถานที่เอาประกัน / สถานที่เกิดเหตุ" })}:{" "}
                        </p>
                        <textarea
                            disabled={is_reference}
                            name="topic"
                            style={{
                                resize: 'none',
                                height: "50px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            // value={address}
                            // onChange={(e) => setAddress(e.target.value)}
                            value={contact}
                            onChange={(e) => setContact(e.target.value)}
                        />

                    </div>

                    <div className="form-inline" style={{ marginBottom: "5px" }}>

                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "104018", defaultMessage: "บ้านเลขที่" })}
                        </p>
                        <input

                            name="remark"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </div>

                    <div className="form-inline" style={{ marginBottom: "5px" }}>

                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "104018", defaultMessage: "ซอย" })}
                        </p>
                        <input

                            name="remark"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={addressDetail}
                            onChange={(e) => setAddressDetail(e.target.value)}
                        />
                    </div>

                    <div className="form-inline" style={{ marginBottom: "5px" }}>

                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "104018", defaultMessage: "ถนน" })}
                        </p>
                        <input

                            name="remark"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={addressDetail2}
                            onChange={(e) => setAddressDetail2(e.target.value)}
                        />
                    </div>

                    {/* <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "###", defaultMessage: "ที่อยู่ในการติดต่อ" })}:{" "}
                        </p>
                        <textarea
                            disabled={is_reference}
                            name="topic"
                            style={{
                                resize: 'none',
                                height: "50px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            // value={contact}
                            // onChange={(e) => setContact(e.target.value)}
                            value={'บ้านเลขที: ' + address.addressDetail + ", " + 'ซอย: ' + address.addressDetail1 + ", " + 'ถนน: ' + address.addressDetail2}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </div> */}

                    {/* <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "###", defaultMessage: "สถานที่เอาประกัน / สถานที่เกิดเหตุ" })}:{" "}
                        </p>
                        <textarea
                            disabled={is_reference}
                            name="topic"
                            style={{
                                resize: 'none',
                                height: "50px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            // value={address}
                            // onChange={(e) => setAddress(e.target.value)}
                            value={contact}
                            onChange={(e) => setContact(e.target.value)}
                        />

                    </div> */}

                    <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "###", defaultMessage: "จังหวัด" })}:{" "}
                        </p>
                        <select
                            disabled={is_reference}
                            as="select"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={Number(province)}
                            onChange={e => {
                                setProvince(e.target.value)
                                setDistrict('')
                                setSubdistrict('')
                                setZipcode('')
                                let temp = e.target.value
                                getDistrict2(provinceList.filter(e => e.code == temp)[0].code)
                            }}
                        >
                            <option value="">{intl.formatMessage({ id: "###", defaultMessage: "โปรดเลือก" })}</option>
                            {provinceList &&
                                provinceList.map((result, index) => {
                                    return (
                                        <option key={index} value={result.code}>
                                            {result.name_th}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>

                    <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "###", defaultMessage: "อำเภอ" })}:{" "}
                        </p>
                        <select
                            disabled={is_reference}
                            as="select"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={Number(district)}
                            onChange={e => {
                                setDistrict(e.target.value)
                                setSubdistrict('')
                                setZipcode('')
                                let temp = e.target.value
                                getSubDistrict(districtList.filter(e => e.id == temp)[0].code)
                            }}
                        >
                            <option value="">{intl.formatMessage({ id: "###", defaultMessage: "โปรดเลือก" })}</option>
                            {districtList &&
                                districtList.map((result, index) => {
                                    return (
                                        <option key={index} value={(result.id)}>
                                            {result.name_th}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>

                    <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "###", defaultMessage: "ตำบล" })}:{" "}
                        </p>
                        <select
                            disabled={is_reference}
                            as="select"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={Number(subDistrict)}
                            onChange={e => {
                                setSubdistrict(e.target.value)
                                let temp = e.target.value
                                setZipcode(subDistrictList.filter(e => e.id == temp)[0].zip_code)
                            }}
                        >
                            <option value="">{intl.formatMessage({ id: "###", defaultMessage: "โปรดเลือก" })}</option>
                            {subDistrictList &&
                                subDistrictList.map((result, index) => {
                                    return (
                                        <option key={index} value={result.id}>
                                            {result.name_th}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>

                    <div className="form-inline" style={{ marginBottom: "5px" }}>

                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "104018", defaultMessage: "รหัสไปรษณีย์:" })}
                        </p>
                        <input
                            disabled={true}
                            name="item_name"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={zipcode}
                            onChange={(item_name) => setZipcode(item_name.target.value)}
                        //disabled={is_reference}
                        />
                    </div>

                    <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            {intl.formatMessage({ id: "###", defaultMessage: "โปรดระบุรายการที่ท่านเรียกร้อง" })}:{" "}
                        </p>
                        <textarea
                            disabled={is_reference}
                            name="description"
                            style={{
                                resize: 'none',
                                height: "50px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />

                    </div>

                    {<Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                        {(claimStatus == '01' || claimStatus == '02' || claimStatus == '03' || claimStatus == '04' || claimStatus.includes('05') || claimStatus == '06.1' || claimStatus == '06.2') && fileClaimList.length > 0 && <Tab eventKey="home" title="เอกสารพิจารณาเพิ่มเติม">
                            <Card>
                                <CardBody>
                                    {fileClaimList &&
                                        fileClaimList.map((result, index) => {
                                            var fileItem = []
                                            var fullPath = ""
                                            if (result.is_edoc) {
                                                fileItem = isJson(result.edoc_file_lists) ? JSON.parse(result.edoc_file_lists) : result.edoc_file_lists
                                                fullPath = `${baseURLV1}/upload/edoc`
                                            } else {
                                                fileItem = isJson(result.Listitem) ? JSON.parse(result.Listitem) : result.Listitem
                                            }
                                            console.log(fileItem,'<===========')
                                            return (
                                                !result.is_edoc ? 
                                                <div key={result.index} className="form-inline" style={{ marginBottom: "5px" }}>
                                                <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                                    <p style={{ marginTop: "15px", width: "20%" }}>
                                                        {result.subDoctype || ''}
                                                    </p>
                                            
                                                    <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                                                        {fileItem && fileItem.map((item, index) => (
                                                            <Button
                                                                key={index}
                                                                className="m-2 DowloadButton"
                                                                onClick={() => handleButtonClick(item, index)}
                                                            >
                                                                <i className={item.includes('.pdf') ? "fas fa-file-pdf" : "fas fa-file-image"} />
                                                                {item}
                                                            </Button>
                                                        ))}
                                                    </div>
                                                </div>
                                            
                                                {/* แสดงตัวอย่างรูปภาพด้านล่างเมื่อปุ่มถูกกด */}
                                                {/* {imageUrl && (
                                                    <div className="image-preview" style={{ marginTop: "5px" }}>
                                                        <img src={imageUrl} alt="Preview" />
                                                    </div>
                                                )} */}
                                            </div>
                                            
                    
                                                    : <div key={index} className="form-inline" style={{ marginBottom: "5px" }}>
                                                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                                                            {result.subDoctype ? result.subDoctype : ''}
                                                        </p>
                                                        {fileItem.map((item) => {
                                                            return <a href={`${fullPath}/${item.id}`} key={item.id} target="_blank" download={`${fullPath}/${item.id}`}>
                                                                <OverlayTrigger
                                                                    key={'overlay_' + item.id}
                                                                    placement={'top'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${item.id}`}>
                                                                            {item.name}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <Button className="m-2 btn-edoc">
                                                                        <i className={item.type == 'application/pdf' ? "fas fa-file-pdf" : "fas fa-file-image"} />
                                                                        {item.name}
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </a>
                                                        })
                                                        }
                                                    </div>
                                            );
                                        })
                                    }
                                </CardBody>
                            </Card>

                        </Tab>}
                        {((claimStatus == '04' || claimStatus.includes('05') || claimStatus == '06.1' || claimStatus == '06.2') && fileDocSignList.length > 0) && <Tab eventKey="profile" title="เอกสารลงนาม">
                            <Card>
                                <CardBody>
                                    <h5>เอกสารที่แนบ</h5>
                                    {fileDocSignList &&
                                        fileDocSignList.map((result, index) => {
                                            var fileList = []
                                            var attList = result.file_attachment_list
                                            var fileItem = isJson(result.file_attachment) ? JSON.parse(result.file_attachment) : result.file_attachment
                                            var fullPath = `${filePathURL}/${fileItem}`
                                            var fileName = ""
                                            if (attList && attList.length > 0) {
                                                fileList.push(...result.file_attachment_list, fileItem)
                                            } else {
                                                fileName = fileItem
                                            }
                                            if (Array.isArray(fileItem) == false && fileItem.search(/uploads/g) >= 0) {
                                                fullPath = fileItem
                                                const myArray = fileItem.split("uploads/");
                                                fileName = myArray[myArray.length - 1]
                                            }
                                            if (result.is_edoc) {
                                                fileItem = isJson(result.edoc_attachment_lists) ? JSON.parse(result.edoc_attachment_lists) : result.edoc_attachment_lists
                                                fullPath = `${baseURLV1}/upload/edoc`
                                            }
                                            return result.file_title == 'หนังสือลงนาม' && (
                                                !result.is_edoc ?
                                                    <div key={index} className="form-inline" style={{ marginBottom: "5px" }}>
                                                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                                                            หนังสือลงนาม(ต้นฉบับ)
                                                        </p>
                                                        {fileList.length > 0 ? fileList.map((item, index) => {
                                                            if (item.search(/uploads/g) >= 0) {
                                                                const myArray = item.split("uploads/");
                                                                fileName = myArray[myArray.length - 1]
                                                            }
                                                            else {
                                                                fileName = item
                                                            }
                                                            return <a href={`${filePathURL}/${fileName}`} target="_blank" download={`${filePathURL}/${fileName}`}>
                                                                <Button className="m-2 DowloadButton" variant="danger">
                                                                    <i className={fileName.includes('.pdf') ? "fas fa-file-pdf" : "fas fa-file-image"} />
                                                                    {fileName}
                                                                </Button>
                                                            </a>
                                                        }) :
                                                            fullPath != '' ? <a href={fullPath} target="_blank" download={fullPath}>
                                                                <Button className="m-2 DowloadButton" variant="danger">
                                                                    <i className={fileItem.includes('.pdf') ? "fas fa-file-pdf" : "fas fa-file-image"} />
                                                                    {fileName}
                                                                </Button>
                                                            </a> : null
                                                        }
                                                    </div>
                                                    : result.is_edoc ?
                                                        <div key={index} className="form-inline" style={{ marginBottom: "5px" }}>
                                                            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                                                                หนังสือลงนาม(ต้นฉบับ)
                                                            </p>
                                                            {fileItem.map((item, index) => {
                                                                return <a href={`${fullPath}/${item.id}`} key={item.id} target="_blank" download={`${fullPath}/${item.id}`}>
                                                                    <OverlayTrigger
                                                                        key={'overlay_' + index}
                                                                        placement={'top'}
                                                                        overlay={
                                                                            <Tooltip id={`tooltip-${index}`}>
                                                                                {item.name}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <Button className="m-2 btn-edoc" variant="danger">
                                                                            <i className={item.type == 'application/pdf' ? "fas fa-file-pdf" : "fas fa-file-image"} />
                                                                            {item.name}
                                                                        </Button>
                                                                    </OverlayTrigger>
                                                                </a>
                                                            })
                                                            }
                                                        </div>
                                                        : null
                                            );
                                        })
                                    }

                                    <hr></hr>

                                    <h5>เอกสารที่ตอบกลับ</h5>
                                    {fileDocSignList &&
                                        fileDocSignList.map((result, index) => {
                                            let fileItem = isJson(result.file_request_lists) ? JSON.parse(result.file_request_lists) : result.file_request_lists
                                            var fullPath = ""
                                            if (result.is_edoc) {
                                                fileItem = isJson(result.edoc_request_lists) ? JSON.parse(result.edoc_request_lists) : result.edoc_request_lists
                                                fullPath = `${baseURLV1}/upload/edoc`
                                            }
                                            return (
                                                !result.is_edoc ? <div key={index} className="form-inline" style={{ marginBottom: "5px" }}>
                                                    <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                                                        {result.file_title ? result.file_title : ''}
                                                    </p>
                                                    {fileItem && fileItem.map((item, index) => {
                                                        return <a href={`${filePathURL}/${item}`} target="_blank" download={item}>
                                                            <Button className="m-2 DowloadButton">
                                                                <i className={item.includes('.pdf') ? "fas fa-file-pdf" : "fas fa-file-image"} />
                                                                {item}
                                                            </Button>
                                                        </a>
                                                    })}
                                                
                                                </div>
                                                    : <div key={index} className="form-inline" style={{ marginBottom: "5px" }}>
                                                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                                                            {result.file_title ? result.file_title : ''}
                                                        </p>
                                                        {fileItem.map((item, index) => {
                                                            return <a href={`${fullPath}/${item.id}`} key={item.id} target="_blank" download={`${fullPath}/${item.id}`}>
                                                                <OverlayTrigger
                                                                    key={'overlay_' + item.id}
                                                                    placement={'top'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${item.id}`}>
                                                                            {item.name}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <Button className="m-2 btn-edoc" >
                                                                        <i className={item.type == 'application/pdf' ? "fas fa-file-pdf" : "fas fa-file-image"} />
                                                                        {item.name}
                                                                    </Button>

                                                                </OverlayTrigger>
                                                            </a>
                                                        })
                                                        }
                                                    </div>
                                            );
                                        })
                                    }
                                </CardBody>
                            </Card>

                        </Tab>}

                        {(claimStatus == '06.2') && <Tab eventKey="contact" title="เอกสารปฏิเสธกรรมธรรม์">
                            <Card>
                                <CardBody>
                                    {fileRejectList != [] &&
                                        fileRejectList.map((result, index) => {
                                            let hrefLink = result.is_edoc ? result.edoc_file_link : `${filePathURL}/${result.file_lists}`
                                            let isPdf = result.is_edoc ? (result.edoc_file_type == "application/pdf" ? true : false) : hrefLink.includes('.pdf')
                                            let name = result.is_edoc ? result.edoc_name : result.file_lists
                                            return (
                                                <div className="form-inline" style={{ marginBottom: "5px" }}>
                                                    <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                                                        สำเนาเอกสารปฏิเสธกรมธรรม์
                                                    </p>
                                                    <a href={hrefLink} target="_blank" download={hrefLink}>
                                                        <Button className="m-2 DowloadButton">
                                                            <i className={isPdf ? "fas fa-file-pdf" : "fas fa-file-image"} />
                                                            {name}
                                                        </Button>
                                                    </a>

                                                </div>
                                            );
                                        })
                                    }
                                </CardBody>
                            </Card>
                        </Tab>}
                    </Tabs>}




                    <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            {intl.formatMessage({ id: "104018", defaultMessage: "ยอดเงินที่เรียกร้อง" })}
                        </p>
                        <input
                            disabled={is_reference}
                            type="number"
                            name="amount"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={amount}
                            onChange={(item_name) => setAmount(item_name.target.value)}
                        />
                    </div>

                    {claimStatus == '03' || claimStatus == '04' || claimStatus.includes('05') || claimStatus == '06.1' || claimStatus == '06.2' ?
                        <div className="form-inline" style={{ marginBottom: "5px" }}>
                            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                                {intl.formatMessage({ id: "104018", defaultMessage: "ยอดเงินที่อนุมัติ" })}
                            </p>
                            <input
                                disabled={true}
                                type="text"
                                name="payment_amount"
                                maxLength="100"
                                style={{
                                    height: "38px",
                                    width: "530px",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                value={paymentAmount}
                            />
                        </div> : <div className="form-inline" style={{ width: "63%", height: "40px", marginBottom: "5px", backgroundColor: "white" }}></div>}

                    <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "###", defaultMessage: "สกุลเงิน" })}:{" "}
                        </p>
                        <select
                            disabled={is_reference}
                            as="select"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={currency}
                            onChange={e => setCurrency(e.target.value)}
                        >
                            <option value="">{intl.formatMessage({ id: "###", defaultMessage: "โปรดเลือก" })}</option>
                            {currencyList &&
                                currencyList.map((result, index) => {
                                    return (
                                        <option key={index} value={result.key}>
                                            {result.name}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>

                    <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            {intl.formatMessage({ id: "###", defaultMessage: "ระบุวิธีรับค่าสินไหม" })}:{" "}
                        </p>
                        <select
                            disabled={is_reference}
                            as="select"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={payment}
                            onChange={e => setPayment(e.target.value)}
                        >
                            <option value="">{intl.formatMessage({ id: "###", defaultMessage: "โปรดเลือก" })}</option>
                            {paymentList &&
                                paymentList.map((result, index) => {
                                    return (
                                        <option key={index} value={result.key}>
                                            {result.name}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>

                    {payment == "transfer" && <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            {intl.formatMessage({ id: "104018", defaultMessage: "เลขที่บัญชี" })}
                        </p>
                        <input
                            disabled={is_reference}
                            name="amount"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={bank_account_number}
                            onChange={(item_name) => setBank_account_number(item_name.target.value)}
                        />
                    </div>}

                    {payment == "transfer" && <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            {intl.formatMessage({ id: "104018", defaultMessage: "ชื่อบัญชี" })}
                        </p>
                        <input
                            disabled={is_reference}
                            name="amount"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={bank_account_name}
                            onChange={(item_name) => setBank_account_name(item_name.target.value)}
                        />
                    </div>}

                    {payment == "transfer" && <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            {intl.formatMessage({ id: "104018", defaultMessage: "ธนาคาร" })}
                        </p>
                        <input
                            disabled={is_reference}
                            name="amount"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={bank_account_trnasfer}
                            onChange={(item_name) => setBank_account_trnasfer(item_name.target.value)}
                        />
                    </div>}

                    {payment == "transfer" && <div className="form-inline" style={{ marginBottom: "5px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            {intl.formatMessage({ id: "104018", defaultMessage: "สาขา" })}
                        </p>
                        <input
                            disabled={is_reference}
                            name="amount"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={bank_account_branch}
                            onChange={(item_name) => setBank_account_branch(item_name.target.value)}
                        />
                    </div>}

                    {/* <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "###", defaultMessage: "สถานที่เอาประกัน / สถานที่เกิดเหตุ" })}:{" "}
                        </p>
                        <textarea
                            disabled={is_reference}
                            name="topic"
                            style={{
                                resize: 'none',
                                height: "50px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            // value={address}
                            // onChange={(e) => setAddress(e.target.value)}
                            value={contact}
                            onChange={(e) => setContact(e.target.value)}
                        />

                    </div> */}

                    {/* <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "###", defaultMessage: "ที่อยู่ในการติดต่อ" })}:{" "}
                        </p>
                        <textarea
                            disabled={is_reference}
                            name="topic"
                            style={{
                                resize: 'none',
                                height: "50px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            // value={contact}
                            // onChange={(e) => setContact(e.target.value)}
                            value={address.addressDetail + " " +address.addressDetail1+ " " +address.addressDetail2}
                            onChange={(e) => setAddress(e.target.value)}
                        />

                    </div> */}

                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                            {intl.formatMessage({ id: "###", defaultMessage: "หมายเหตุ" })}:{" "}
                        </p>
                        <textarea
                            disabled={is_reference}
                            name="topic"
                            style={{
                                resize: 'none',
                                height: "50px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                        />

                    </div>

                    <div className="row">

                        <div
                            className="col-2"
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                                grid: "menu"
                            }}
                        ></div>

                        <div
                            className="form-inline col-8"
                            style={{
                                marginBottom: "5px",
                            }}>



                            {(claimStatus != '06.1' && claimStatus != '06.2' && !claimNoRef && !isSubmit && !is_reference && (role == '1' || role == '2')) &&
                                <button className="btn btn-primary wbt m-2" onClick={() => check()}>
                                    ดำเนินการต่อ
                                </button>
                            }

                            {isLoadingUpdate ? <button className="btn btn-primary m-2"><div><i className="fa fa-spinner fa-spin"></i> รอสักครู่... </div></button> : (claimStatus != '06.1' && claimStatus != '06.2' && !is_reference && (role == '1' || role == '2')) && <button className="btn btn-primary wbt m-2" onClick={() => {
                                handleSave()
                                setIsLoadingUpdate(true)
                            }}>
                                อัปเดตข้อมูล
                            </button>}
                            {isLoadingCopy ? <button className="btn btn-primary m-2"><div><i className="fa fa-spinner fa-spin"></i> รอสักครู่... </div></button> : (claimStatus != '06.1' && claimStatus != '06.2' && !claimStatus.includes('05') && !is_reference && (role == '1' || role == '2')) &&
                                <button className="btn btn-primary wbt m-2" onClick={() => {
                                    CreateDuplicate()
                                    setIsLoadingCopy(true)
                                }}>
                                    คัดลอกข้อมูล
                                </button>
                            }
                            {isLoadingUndo ? <button className="btn btn-primary m-2"><div><i className="fa fa-spinner fa-spin"></i> รอสักครู่... </div></button> : (!claimNoRef && !isSubmit && !is_reference && (claimStatus == '02' || claimStatus == '03' || claimStatus == '04') && (role == '1' || role == '2')) && <button className="btn btn-primary wbt m-2" onClick={() => {
                                handleUndo()
                                setIsLoadingUndo(true)
                            }}>
                                ค่าเริ่มต้น
                            </button>}

                            <button className="btn btn-primary wbt m-2" onClick={() => history.push({ pathname: '/ClaimManagement' })}>
                                ย้อนกลับ
                            </button>
                        </div>

                    </div>

                    <div className="row">

                        <div className="col-2">
                            <div
                                style={{
                                    marginTop: "15px",
                                    float: "left",
                                    width: "20%",
                                }}
                            ></div>
                        </div>

                        <div
                            className="form-inline col-8"
                            style={{
                                marginBottom: "5px",
                            }}>


                            {isLoadingNoti ? <button className="btn btn-primary m-2"><div><i className="fa fa-spinner fa-spin"></i> รอสักครู่... </div></button> : (claimStatus == '03' || claimStatus == '04') && (!is_reference) && (role == '1' || role == '2') &&
                                <button className="btn btn-primary m-2" onClick={() => {
                                    handleCheckPayment()
                                    setIsLoadingNoti(true)
                                }}>
                                    แจ้งผลพิจารณา
                                </button>
                            }
                            {/* {(claimStatus == '03' || claimStatus == '04') && (insurranceType != 'pa' && insurranceType != 'dan' && insurranceType != 'ta' && insurranceType != 'medical' && !is_reference) && (role == '1' || role == '2') &&
                            <button className="btn btn-primary mr-5" onClick={() => {
                                setShowNotice(true)
                            }}>
                                แจ้งผลพิจารณา(ไม่ตรวจสอบยอดเงินอนุมัติ)
                            </button>
                        } */}



                            {isLoadingNotCompleteCheck1 ? <button className="btn btn-primary m-2" style={{ backgroundColor: "lightgreen", borderColor: 'lightgreen' }}><div><i className="fa fa-spinner fa-spin"></i> รอสักครู่... </div></button> : (claimStatus == '04' && !is_reference && role == '1') &&
                                <button className="btn btn-primary m-2" style={{ backgroundColor: "lightgreen", borderColor: 'lightgreen' }} onClick={(e) => {
                                    setNotCompleteCheck("ทบทวนผลพิจารณา ขอเอกสารเพิ่มเติม")
                                    setIsLoadingNotCompleteCheck1(true)
                                }}>
                                    ทบทวนผลพิจารณาขอเอกสารเพิ่มเติม
                                </button>
                            }



                            {isLoadingComplete1 ? <button className="btn btn-primary m-2" style={{ backgroundColor: "pink", borderColor: 'pink' }}><div><i className="fa fa-spinner fa-spin"></i> รอสักครู่... </div></button> : (claimStatus == '04' && !is_reference && (role == '1' || role == '2')) &&
                                <button className="btn btn-primary m-2" style={{ backgroundColor: "pink", borderColor: 'pink' }} onClick={(e) => {
                                    handleComplete("ทบทวนผลพิจารณา ไม่ขอเอกสารเพิ่มเติม")
                                    setIsLoadingComplete1(true)
                                }}>
                                    ทบทวนผลพิจารณา<span className="mb-0" style={{ color: "red" }}>ไม่ขอเอกสารเพิ่มเติม</span>
                                </button>
                            }



                            {(claimNoRef && claimStatus == '01' || claimStatus == "02" || isSubmit) && !is_reference && (role == '1' || role == '2') ? <>
                                {isLoadingComplete2 ? <button className="btn btn-success m-2"><div><i className="fa fa-spinner fa-spin"></i> รอสักครู่... </div></button> : <button className="btn btn-success m-2" onClick={(e) => {
                                    handleComplete("Confirm เอกสารครบ")
                                    setIsLoadingComplete2(true)
                                }}>
                                    Confirm เอกสารครบ
                                </button>}
                                {isLoadingNotCompleteCheck2 ? <button className="btn btn-info m-2"><div><i className="fa fa-spinner fa-spin"></i> รอสักครู่... </div></button> : <button className="btn btn-info m-2" onClick={(e) => {
                                    setNotCompleteCheck("Confirm เอกสารไม่ครบ")
                                    setIsLoadingNotCompleteCheck2(true)
                                }}>
                                    Confirm เอกสารไม่ครบ
                                </button>}
                            </>
                                : ''
                            }


                            {isLoadingReject ? <button className="btn btn-warning m-2"><div><i className="fa fa-spinner fa-spin"></i> รอสักครู่... </div></button> : (claimStatus == '01' || claimStatus == "02" || claimStatus == "03" || claimStatus == "04") && !is_reference && (role == '1' || role == '2') ?
                                <button type="button" className="btn btn-warning m-2" onClick={() => {
                                    setShowRejectPopup(true)
                                    setIsLoadingReject(true)
                                }}>
                                    ไม่พบกรมธรรม์ หรือ ปฎิเสธความคุ้มครอง
                                </button>
                                : ""
                            }

                        </div>
                    </div>



                    <Modal show={showPopup}>
                        <Modal.Header closeButton>
                            <Modal.Title>กรุณายืนยันหมายเลขจาก IIA</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <NumberFormat
                                format="##-#-###-######"
                                mask="_"
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                placeholder="กรุณากรอกข้อมูล"
                                onChange={(e) => setClaimNo(e.target.value)}
                            />

                        </Modal.Body>
                        <Modal.Footer>
                            {isLoadingIIA ? <div><i className="fa fa-spinner fa-spin"></i> รอสักครู่... </div> : <Button variant="primary" onClick={() => {
                                handleCreateClaimNo()
                                setIsLoadingIIA(true)
                            }}>
                                ยืนยัน
                            </Button>}
                            <Button variant="secondary" onClick={() => { handleCloseCreateClaimNo() }}>
                                ยกเลิก
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showSearchPolicy}>
                        <Modal.Header closeButton>
                            <Modal.Title>ค้นหากรมธรรม์</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <input
                                name="policy_no"
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                placeholder="กรุณากรอกข้อมูล"
                                value={txtSearchPolicy}
                                onChange={(e) => {
                                    let valueData = e.target.value
                                    valueData = valueData.replace(/ /g, '')
                                    setTxtSearchPolicy(valueData)
                                    setSearchPolicyNo(valueData)
                                }}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            {isLoadingSearch ? <div><i className="fa fa-spinner fa-spin"></i> รอสักครู่...</div> : <Button variant="primary" onClick={() => { handleSearchPolicyNo() }}>ค้นหา</Button>}
                            <Button variant="secondary" onClick={() => {
                                handleCloseSearchPolicyNo()
                                setIsLoadingSearch(false)
                            }}>
                                ยกเลิก
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        className="modal-center"
                        show={showDetailPolicy}
                        onHide={handleCloseDetailPolicy}
                        size='lg'
                    >
                        <Modal.Header>
                            <h3>รายละเอียดกรมธรรม์</h3>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => handleCloseDetailPolicy(false)}>
                                <span aria-hidden="true" className="x">&times;</span>
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            <p>1. เลขกรรมธรรม์: {policyData.policy_no}</p>
                            <p>2. ชื่อผู้เอาประกัน: {policyData.claimer_fullname}</p>
                            <p>3. สถานที่ตั้งทรัพย์สินที่เอาประกัน: </p>

                            {(displayAddress.length > 0) ? <Table bordered>
                                <thead className="table-header-address">
                                    <tr>
                                        <th>รายการที่อยู่</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {displayAddress}
                                </tbody>
                            </Table> : ""}

                            {(displayAddress != '') ? <ReactPaginate
                                className="my-3 paginationBttns"
                                previousLabel={"ย้อนกลับ"}
                                nextLabel={"ถัดไป"}
                                pageCount={pageCount}
                                onPageChange={handleChangeAddressInModal}
                                containerClassName={"paginationBttns"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"}
                            /> : ""}

                            <p>4. ทุนประกันภัย:  <NumberFormat value={policyData.pol_sum_insure} displayType="text" thousandSeparator={true} decimalScale={2} /></p>
                            <p>5. วันที่เริ่มคุ้มครอง: {moment(policyData.effective_date).format('DD/MM/YYYY')}</p>
                            <p style={{ marginLeft: "15px" }}>วันที่สิ้นสุดความคุ้มครอง: {moment(policyData.expiry_date).format('DD/MM/YYYY')}</p>
                            <p>6. Sub Class: {policyData.sub_class}</p>
                            <p>7. แหล่งงาน: {policyData.department}</p>
                            <p>8. เบี้ยประกันภัย: <NumberFormat value={policyData.pol_net_gross_prem} displayType="text" thousandSeparator={true} decimalScale={2} /></p>
                            <p>9. ภัยเพิ่มตามกรมธรรม์:</p>
                            {policyData.policy_peril && policyData.policy_peril.length > 0 &&
                                < Table responsive className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Policy Peril Type</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Org Sum Insured</th>
                                            <th scope="col">Org Gross Premium</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displayAddress1}
                                    </tbody>
                                </Table>
                            }

                            {(displayAddress1 != '') ? <ReactPaginate
                                className="my-3 paginationBttns"
                                previousLabel={"ย้อนกลับ"}
                                nextLabel={"ถัดไป"}
                                pageCount={pageCount1}
                                onPageChange={handleChangeAddressInModal1}
                                containerClassName={"paginationBttns"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"}
                            /> : ""}

                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => handleCloseDetailPolicy(false)}>ปิดหน้าต่าง</button>
                        </Modal.Footer>
                    </Modal>
                    {selectedReject &&
                        <Modal
                            className="modal-center"
                            backdrop={true}
                            show={showRejectPopup}
                            onHide={handleClosePopupRejected}
                            centered
                            size='md'
                        >
                            <Modal.Header>
                                ยืนยันไม่พบกรมธรรม์ หรือ ปฏิเสธความคุ้มครอง
                            </Modal.Header>
                            <Modal.Body>
                                <div className="form-check form-check-custom form-check-solid me-10">
                                    <input className="form-check-input" type="radio" name="inlineCheckbox" id="inlineCheckbox1" value="1" defaultChecked={1} onChange={(e) => handleChosenRejected(e)} />
                                    <label className="form-check-label fw-bold" for="inlineCheckbox1">ไม่พบกรมธรรม์</label>
                                </div>

                                <div className="form-check form-check-custom form-check-solid me-10">
                                    <input className="form-check-input" type="radio" name="inlineCheckbox" id="inlineCheckbox2" value="2" onChange={(e) => handleChosenRejected(e)} />
                                    <label className="form-check-label fw-bold" for="inlineCheckbox2">ปฏิเสธความคุ้มครอง</label>
                                    {selectedReject == '2' && <>
                                        <p style={{ color: "red" }}>* แนบเอกสารหนังสือชี้แจงลูกค้า</p>
                                        <input type="file" multiple={true} accept=".pdf" onChange={(e) => handleUploadPdfOnly(e.target.files, true)} />
                                    </>
                                    }
                                </div>

                            </Modal.Body>
                            <Modal.Footer>
                                <button type="button" className="btn btn-secondary btnOk" data-dismiss="modal" onClick={() => handleSendNoticeReject()}>ตกลง</button>
                                <button type="button" className="btn btn-secondary btnCancel" data-dismiss="modal" onClick={() => handleClosePopupRejected()}>ยกเลิก</button>
                            </Modal.Footer>
                        </Modal>
                    }

                    {showNotice && <Modal
                        className="modal-center"
                        backdrop={true}
                        show={showNotice}
                        onHide={handleClose}
                        centered
                        size='md'
                    >
                        <Modal.Header>
                            ยืนยันการแจ้งผลพิจารณา
                        </Modal.Header>
                        <Modal.Body>

                            <div className="mb-3"> ยอดเงินที่อนุมัติ: {paymentAmount ? <span>{paymentAmount} บาท</span> : <span>0 บาท</span>}  </div>

                            <div >
                                <p style={{ color: "red" }}>กรุณาแนบหนังสือลงนามให้ลูกค้า*</p>
                                <input type="file" accept=".pdf" onChange={e => handleUploadPdfOnly(e.target.files, false)} />
                            </div>
                            <p>เลือกแนบเอกสารประกอบการชดใช้ค่าสินไหมทดแทน(เพิ่มเติม)</p>
                            <div className="form-check form-check-custom form-check-solid me-10">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" onChange={e => {
                                    setRequireCopyCard(!requireCopyCard)
                                    pushApproveFile(e.target.checked, {
                                        "file_title": "สำเนาบัตรประจำตัวประชาชน ของ \"ผู้เอาประกันภัย\"",

                                    })
                                }} />
                                <label className="form-check-label" for="inlineCheckbox1" >สำเนาบัตรประจำตัวประชาชนของ"ผู้เอาประกันภัย"</label>
                            </div>
                            <div className="form-check form-check-custom form-check-solid me-10">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2" onChange={e => {
                                    setRequireCertificate(!requireCertificate)
                                    pushApproveFile(e.target.checked, {
                                        "file_title": "สำเนาหนังสือรับรองบริษัทฯ",

                                    })
                                }} />
                                <label className="form-check-label fw-bold" for="inlineCheckbox2">สำเนาหนังสือรับรองบริษัทฯ</label>
                            </div>
                            <div className="form-check form-check-custom form-check-solid me-10">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox3" onChange={e => {
                                    setRequireBookBank(!requireBookBank)
                                    pushApproveFile(e.target.checked, {
                                        "file_title": "สำเนาสมุดบัญชีเงินฝาก \"ผู้เอาประกันภัย\" สำหรับรับโอนเงินค่าสินไหมทดแทน",

                                    })
                                }} />
                                <label className="form-check-label fw-bold" for="inlineCheckbox3">สำเนาสมุดบัญชีเงินฝาก "ผู้เอาประกันภัย" สำหรับรับโอนเงินค่าสินไหมทดแทน</label>
                            </div>
                            <div className="form-check form-check-custom form-check-solid me-10">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox4" onChange={e => {
                                    setRequireCopy20(!requireCopy20)
                                    pushApproveFile(e.target.checked, {
                                        "file_title": "สำเนา ภ.พ 20",

                                    })
                                }} />
                                <label className="form-check-label fw-bold" for="inlineCheckbox4">สำเนา ภ.พ 20</label>
                            </div>
                            {
                                otherDoc && otherDoc.map((item, index) => {
                                    let id = `inlineCheckbox_${index}`
                                    return (
                                        <div className="form-check form-check-custom form-check-solid me-10">
                                            <input className="form-check-input" type="checkbox" id={id} onChange={e => {
                                                pushApproveFile(e.target.checked, {
                                                    "file_title": item.file_title,
                                                })
                                            }} />
                                            <label className="form-check-label fw-bold" for={id}>{item.file_title}</label>
                                        </div>
                                    )
                                })
                            }

                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary btnOk" data-dismiss="modal" onClick={() => handleSendNoticeApprove()}>ตกลง</button>
                            <button type="button" className="btn btn-secondary btnCancel" data-dismiss="modal" onClick={() => {
                                handleClose()
                            }}>ยกเลิก</button>
                        </Modal.Footer>
                    </Modal>
                    }

                    {notCompleteCheck && <Modal
                        className="modal-center"
                        backdrop={true}
                        show={notCompleteCheck}
                        onHide={handleCloseNotComplease}
                        centered
                        size='md'
                    >
                        <Modal.Header>
                            เลือกรายการเอกสารไม่ครบ
                        </Modal.Header>
                        <Modal.Body>
                            {/*<p>เลือกแนบเอกสารประกอบการชดใช้ค่าสินไหมทดแทน(เพิ่มเติม)</p>*/}
                            {fileClaimList && fileClaimList.map((result, index) => {
                                return (
                                    <div key={index} className="form-inline" style={{ marginBottom: "15px" }}>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" defaultChecked={!isSend ? (result.Listitem.length == 0 || result.Listitem == '[]') : confirmCheck[index].is_required} id="flexCheckIndeterminate" onChange={e => {
                                                pushNotCorrect2(e.target.checked, result)
                                                // console.log(e.target.checked)
                                            }} />
                                            <label className="form-check-label pl-2" for="flexCheckDefault">
                                                {result.subDoctype ? result.subDoctype : ''}
                                            </label>
                                        </div>
                                    </div>
                                );
                            })
                            }
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="flexCheckIndeterminate" onChange={e => {
                                    setRequireRemark2(!requireRemark2)
                                }} />
                                <label className="form-check-label pl-2" for="flexCheckDefault">เอกสารอื่นๆ</label>
                            </div>
                            {requireRemark2 &&
                                <div >
                                    <input
                                        name="remark"
                                        maxLength="100"
                                        className="form-control"
                                        placeholder="อื่นๆ โปรดระบุ"
                                        onChange={e => {
                                            setRequireRemarkTxt2(e.target.value)
                                        }}
                                    />
                                </div>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary btnOk" data-dismiss="modal" onClick={() => handleNotComplete()}>ตกลง</button>
                            <button type="button" className="btn btn-secondary btnCancel" data-dismiss="modal" onClick={() => {
                                handleCloseNotComplease()
                            }}>ยกเลิก</button>
                        </Modal.Footer>
                    </Modal>
                    }
                </CardBody>
            </Card>
        </>
    );
};
